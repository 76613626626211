<template>
  <div class="p-2">
    <h1 class="text-2xl my-2">Les positions</h1>
    <div v-for="position in positions" :key="position.name" class="rounded-xl shadow bg-gray-700 my-4 p-1 grid grid-cols-4">
      <div class="content-center">
        <img class="h-14 w-auto mx-auto" :src="position.image">
      </div>
      <div class="col-span-3 p-1">
        <div class="text-left text-lg font-semibold">
          {{ position.name }}
        </div>
        <div class="text-justify">
          {{ position.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Position",
  data () {
    return {
      positions: [
        {
          'name': 'Leuleu',
          'description': 'La levrette est une position sexuelle où l\'un des partenaires se présente de dos, tandis que l\'autre pénètre le premier par derrière1.\n' +
              '\n' +
              'Le terme même de « levrette » peut recouvrir plusieurs variantes puisque l\'on peut la pratiquer debout, à quatre pattes ou couché. La levrette peut donner lieu à une pénétration vaginale, digitale, anale, ou avec un objet.',
          'image': require('../assets/leuleu.png')
        },
        {
          'name': 'Assis',
          'description': 'Mettre (quelqu\'un) dans la posture d\'appui sur le derrière (sur un siège, etc.).',
          'image': require('../assets/assis.png')
        },
        {
          'name': 'Debout',
          'description': 'Qui est posé verticalement.',
          'image': require('../assets/debout.png')
        },
        {
          'name': 'Poirier',
          'description': 'Tenir en équilibre sur les mains, les pieds en l\'air et la tête en bas.',
          'image': require('../assets/poirier.png')
        },
        {
          'name': 'A genoux',
          'description': 'Action d\'être assis de telle sorte que les genoux soient contre terre. C\'est une attitude religieuse d\'adoration ou de soumission.',
          'image': require('../assets/genou.png')
        },
        {
          'name': 'Gainage',
          'description': 'Le gainage est un exercice d\'entraînement qui sert à renforcer les muscles abdominaux et dorsaux. Sa forme la plus fréquente est « la planche », qui consiste à se positionner face au sol, en appui sur les pointes de pied et les avant-bras, coudes à l\'aplomb des épaules.',
          'image': require('../assets/gainage.png')
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
